<template>
  <List el="md" style="max-width: 1200px">
    <List el="column-between xsm">
      <Text el="h2">Resources</Text>
    </List>
    <Text el="h4">Class Schedule</Text>
    <List el="xxsm">
      <ul el>
        <li>
          Sunday noon-5pm (15 min break).
          <b>Note: make sure you eat lunch before class</b>
        </li>
        <li>Mon, Tue, & Wed 7pm-9:30pm</li>
        <li>Self-study Thu, Fri, & Sat</li>
      </ul>
    </List>
    <Text el="h4">Helpful Links</Text>
    <List el="xxsm">
      <Link href="https://edabit.com/">Edabit</Link>
      <Link href="https://codingbat.com/java">CodingBat</Link>
      <Link href="http://www.webdriveruniversity.com/"
        >WebdriverUniversity</Link
      >
      <Link to="/docs">Alphaleaf UI Playground</Link>
      <Link href="https://budget-designer.web.app/">Budget Designer</Link>
      <Link href="https://alphaleaf-clubhouse.burkedesigns.workers.dev/">Alphaleaf HOA</Link>
      <Link href="https://alphaleaf-selenium.burkedesigns.workers.dev/">Alphaleaf Selenium</Link>
    </List>
    <Text el="h4">Alphaleaf Example API</Text>
    <Text><b>Endpoint:</b> https://alphaleaf.burkedesigns.workers.dev/api</Text>
    <table el="table">
      <thead>
        <tr>
          <th width="150px">Request Type</th>
          <th>URL</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>GET</td>
          <td>/events</td>
          <td>List all events</td>
        </tr>
        <tr>
          <td>GET</td>
          <td>/events/:id</td>
          <td>Get event by ID</td>
        </tr>
        <tr>
          <td>GET</td>
          <td>/users</td>
          <td>List all users</td>
        </tr>
        <tr>
          <td>GET</td>
          <td>/users/:uid</td>
          <td>Get user by UID</td>
        </tr>
      </tbody>
    </table>


    <List el="md" v-if="$store.getters.isAdmin">
    <Text el="h3">Test Clubhouse Website</Text>
      <List el="column sm">
        <div>Test Clubhouse Branch</div>
        <select v-model="clubhouse.branch" v-if="clubhouse.branch != null">
          <option value="bugs">bugs</option>
          <option value="staging">staging</option>
        </select>
      </List>
    </List>




  </List>
</template>
<script>
// import router from "@/router";
// let filter = require("lodash/filter");
// let lowerCase = require("lodash/lowerCase");
// let includes = require("lodash/includes");

export default {
  components: {
    // Layout,
  },
  data() {
    return {
      clubhouse:{
        branch:null,
      }
    };
  },
  watch:{
    'clubhouse.branch':function (val, oldVal){
      if(oldVal != null){
        this.setClubhouseBranch(val);
      }
    }
  },
  methods: {
    async getClubhouseBranch(){
      // let x = this;
      let branch = await fetch('https://alphaleaf-clubhouse.burkedesigns.workers.dev/branch');
      let result = await branch.text();
      this.clubhouse.branch = result;
    },
    async setClubhouseBranch(val){
      await fetch(`https://alphaleaf-clubhouse.burkedesigns.workers.dev/branch?id=${val}`);
    },
  },
  computed: {},
  mounted() {
    let pathArr = this.$route.path.split("/");
    let endPath = pathArr.pop();
    if (endPath == "upload") {
      this.$store.commit("collapseSection", false);
    } else {
      this.$store.commit("collapseSection", true);
    }
    this.getClubhouseBranch();

  },
};
</script>
<style lang="scss" scoped></style>
